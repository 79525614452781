export const start_chat = `/chat/start_chat`;
export const chat = `/chat/chat`;
export const register = `/register`;
export const login_user = `/login`;
export const add_feedback = `/feedback`;
export const change_password = `/reset-password`;
export const ingest_file = `/ingest/ingest_data`;
export const delete_file = `/ingest/delete/`;
export const ingested_files = `/ingest/ingested_files/`;
export const list_files = `/ingest/list_files`;
