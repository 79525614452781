import React, { useEffect, useState } from "react";
import LogoIcon from "../../assets/svg/logoIcon";
import { useNavigate } from "react-router-dom";
import { delete_file, ingested_files, list_files } from "../../utils/endpoints";
import BackButton from "../common/backButton";
import Footer from "../common/footer";
import ChatHistory from "../common/chatHistory";
import createAxiosInstance from "../../utils/axiosConfig";

const UploadFiles = () => {
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userData") || "{}");
  const [errors, setErrors] = useState<any>({});
  const [responseError, setResponseError] = useState<string>("");
  const [file, setFile] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [option, setOption] = useState<number>(0);

  const [isFadingOut, setIsFadingOut] = useState(false);
  const [isFadingIn, setIsFadingIn] = useState(false);

  const [listFiles, setListFiles] = useState<any[]>([]);

  const [deleteFormData, setDeleteFormData] = useState({
    file_name: "",
  });

  const validateDeleteForm = (
    formData: any,
    setErrors: (errors: any) => void
  ) => {
    const tempErrors: any = {};

    Object.keys(formData).forEach((key) => {
      if (!formData[key as keyof typeof formData]) {
        if (
          formData?.requires_enrichment == false ||
          formData?.requires_enrichment == true
        ) {
          return;
        } else {
          tempErrors[key] = `${key} is required`;
        }
      }
    });

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSelectChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setDeleteFormData({ ...deleteFormData, [name]: value });
    validateDeleteForm({ [name]: value }, setErrors);
  };

  const handleDeleteSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateDeleteForm(deleteFormData, setErrors)) return;

    const payload = {
      file_name: `use_case_${deleteFormData.file_name}`,
    };

    try {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.post(delete_file, payload);

      if (response.data?.error) {
        setResponseError(response?.data?.error);
        handleOptionSelect(2);
      } else {
        setDeleteFormData({
          file_name: "",
        });
        if (response?.data?.num_of_docs_deleted > 0) {
          handleOptionSelect(2);
        } else {
          setResponseError("Some error occured while deleting the file.");
        }
      }
    } catch (err) {
      setResponseError("Some errors occured. Please try again later.");
      console.log(err);
    }
  };

  const handleFileChange = (event: any) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      setErrors({ file: "Please select a file before submit." });
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const axiosInstance = createAxiosInstance({
        "Content-Type": "multipart/form-data",
      });

      const response = await axiosInstance.post("/upload/upload", formData);
      console.log("File uploaded successfully:", response.data);
      setResponseError("File uploaded successfully");
      setLoading(false);
      setFile(null);
    } catch (error) {
      console.error("Error uploading file:", error);
      setResponseError("Error uploading file. Try again later.");
    }
  };

  useEffect(() => {
    if (!userData?.is_admin || userData?.is_admin == 0) {
      navigate("/");
    }
    getFilesList();
  }, []);

  const handleOptionSelect = (option: number) => {
    setIsFadingOut(true);
    setOption(option);
    setTimeout(() => {
      // setIsFadingOut(false);
      setIsFadingIn(true);
    }, 1000);
  };

  const getFilesList = async () => {
    try {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(ingested_files);
      setListFiles(response?.data?.ingested_files);
    } catch (error) {
      console.error("Error uploading file:", error);
      setResponseError("Error listing files. Try again later.");
    }
  };

  return (
    <section className="authentication h-100">
      <div className="row align-items-center justify-content-center h-100 w-100">
        <div className="col-md-10 col-lg-9 col-xl-6">
          <div className="card  mx-auto text-center mt-5">
            <div className="card-body">
              <div className="mb-3">
                <LogoIcon />
              </div>
              <h2 className="my-3 my-md-4"> Upload New Files </h2>
              <p className="text-secondary">
                Have you already uploaded all new files ?{" "}
                <a href="/ingest">Ingest Data</a>
              </p>
              {/* <form action="" onSubmit={handleSubmit}> */}
              {((option !== 1 && option !== 2) || !isFadingIn) && (
                <ul
                  className={`${
                    option !== 1 && option !== 2 ? "fade-in" : "fade-out"
                  } list-group checklist`}
                >
                  <li
                    className="list-group-item"
                    style={{ background: "#ebe6e3", cursor: "pointer" }}
                    onClick={() => handleOptionSelect(1)}
                  >
                    <input
                      className="form-check-input me-1"
                      type="radio"
                      name="listGroupRadio"
                      id="firstRadio"
                      checked={option === 1} // Check against the state
                      style={{ cursor: "pointer" }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="firstRadio"
                      style={{ cursor: "pointer" }}
                    >
                      I want to update an exisiting file.
                    </label>
                  </li>
                  <li
                    className="list-group-item"
                    style={{ background: "#ebe6e3", cursor: "pointer" }}
                    onClick={() => handleOptionSelect(2)}
                  >
                    <input
                      className="form-check-input me-1"
                      type="radio"
                      name="listGroupRadio"
                      id="secondRadio"
                      checked={option === 2} // Check against the state
                      style={{ cursor: "pointer" }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="secondRadio"
                      style={{ cursor: "pointer" }}
                    >
                      I want to upload a new file
                    </label>
                  </li>
                </ul>
              )}

              {option == 1 && isFadingIn && (
                <form
                  action=""
                  onSubmit={handleDeleteSubmit}
                  className="fade-in"
                >
                  <p className="text-secondary" style={{ textAlign: "left" }}>
                    First delete ingested version :
                  </p>
                  <div className="form-floating mb-3 ">
                    <select
                      className={`form-select ${
                        errors.file_name ? "is-invalid" : ""
                      }`}
                      id="floatingSelect"
                      aria-label="Floating label select example"
                      name="file_name"
                      value={deleteFormData.file_name}
                      onChange={handleSelectChange}
                    >
                      <option>Select file to delete</option>
                      {listFiles?.map((file) => {
                        return <option value={file}>{file}</option>;
                      })}
                    </select>
                    <label htmlFor="floatingSelect">File</label>
                    {errors.base_path && (
                      <div className="text-danger small">
                        {errors.base_path}
                      </div>
                    )}
                  </div>

                  <div className="mb-3">
                    <button
                      type="submit"
                      className="btn btn-primary w-100"
                      //   onClick={handleUpload}
                      disabled={Object.keys(errors).length > 0}
                    >
                      Update
                    </button>
                  </div>
                </form>
              )}

              {option == 2 && isFadingIn && (
                <div className="fade-in">
                  <div
                    className="form-floating mb-3 fade-in-left"
                    style={{
                      textAlign: "center",
                      justifyContent: "center",
                      margin: "auto",
                    }}
                  >
                    <input
                      type="file"
                      className={`${errors.file ? "is-invalid" : ""} my-4`}
                      id="floatingInput"
                      placeholder="********"
                      name="file"
                      //   value={formData.file}
                      onChange={handleFileChange}
                      accept=".xlsx, .pdf, .docx"
                      style={{ display: "inline-block", margin: "auto" }}
                    />
                    {/* <label htmlFor="floatingInput">File</label> */}

                    {errors.file && (
                      <div className="text-danger small">{errors.file}</div>
                    )}
                  </div>
                  <div className="mb-3">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={handleUpload}
                      disabled={loading}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              )}

              {responseError && <p>{responseError}</p>}
              <div className="mb-3">
                {option >= 1 ? (
                  <div
                    className="btn btn-link w-100"
                    onClick={() => {
                      handleOptionSelect(0);
                      console.log("Option selected", 0);
                    }}
                  >
                    {" "}
                    <i className="bi bi-arrow-left me-2"></i> Back
                  </div>
                ) : (
                  <BackButton />
                )}
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>
        <Footer />
        <ChatHistory
          onNavigate={() => {
            navigate("/");
          }}
        />
      </div>
    </section>
  );
};

export default UploadFiles;
