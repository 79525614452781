import React from "react";
import LogoIcon from "../../assets/svg/logoIcon";
import Footer from "../common/footer";

const PasswordConfirmation = () => {
  return (
    <section className="authentication h-100">
      <div className="row align-items-center justify-content-center h-100 w-100">
        <div className="col-md-10 col-lg-9 col-xl-6">
          <div className="card  mx-auto text-center">
            <div className="card-body">
              <div className="mb-3">
                <LogoIcon />
              </div>
              <h2 className="my-3 my-md-4"> Help is on the way</h2>
              <p className="text-secondary">
                We're combing through our records to find the 1Ciso account for:
                <a href="">Stan@1Ciso.co</a>{" "}
              </p>
              <p>
                If we find a match, you’ll get an email with further
                instructions. If you don’t hear from us in the next 15 minutes,
                please double check that you entered the correct email address
                and check your spam folder.
              </p>

              <div className="mb-3">
                <a href="/" type="submit" className="btn btn-link w-100">
                  <i className="bi bi-arrow-left me-2"></i> Back
                </a>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </section>
  );
};

export default PasswordConfirmation;
