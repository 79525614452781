import React from "react";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <div className="mt-4 btn btn-link" onClick={() => navigate(-1)}>
      <i className="bi bi-arrow-left me-2"></i> Back
    </div>
  );
};

export default BackButton;
