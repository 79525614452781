import React, { useEffect, useRef } from "react";
import MessageList from "./messageList";
import OptionList from "./optionList";
import { useCases } from "../../../utils/constants";

export interface ChatSectionProps {
  chat_id: string;
  useCase: number;
  messages: {
    type: string;
    text: string;
    time: string;
    icon?: boolean;
  }[];
  handleOptionSelect: (
    label: string,
    chat_id: string,
    type: string,
    subCase: string
  ) => void;
  handleUseCaseSelect: (value: number) => void;
  handleSubCase: (value: string) => void;
  showMessage: boolean;
  selectedSubCase: string | undefined;
  setDisabled: (param: boolean) => void;
}

const Messages: React.FC<ChatSectionProps> = React.memo(
  ({
    useCase,
    messages,
    chat_id,
    handleOptionSelect,
    handleUseCaseSelect,
    handleSubCase,
    showMessage,
    selectedSubCase,
    setDisabled,
  }) => {
    const messageEndRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    return (
      <section className="homemain">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="chatsection">
                {useCase === 3 && (
                  <OptionList
                    conversationId={chat_id}
                    handleOptionSelect={handleOptionSelect}
                    handleSubCase={handleSubCase}
                    selectedSubCase={selectedSubCase} // Pass the state
                  />
                )}

                {useCase === 2 && (
                  <div className="d-flex flex-row justify-content-end ">
                    <p className="text-secondary mr-0 text-danger">
                      Please{" "}
                      <a
                        href="/NIST-CSF-Assessment-Live.xlsx"
                        download="NIST-CSF-Assessment-Live.xlsx"
                      >
                        {" "}
                        download
                      </a>{" "}
                      the NIST 2.0 assessment, fill it out and use the results as a guide to engage with the assistant
                    </p>
                  </div>
                )}
                <MessageList
                  messages={messages}
                  showMessage={showMessage}
                  selectUseCase={handleUseCaseSelect}
                  setDisabled={setDisabled}
                />
                <div ref={messageEndRef}></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default Messages;
