import React, { useState } from "react";
import LogoIcon from "../../assets/svg/logoIcon";
import EnvelopeIcon from "../../assets/svg/envelopeIcon";
import axios from "axios";
import Alert from "../common/alerts/alert";
import Footer from "../common/footer";
import createAxiosInstance from "../../utils/axiosConfig";

const ForgotPassword = () => {
  const [alertVisible, setAlertVisible] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailPattern.test(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    try {
      const axiosInstance = createAxiosInstance();

      const response = await axiosInstance.post("/request-password-reset", {
        email,
      });

      if (response.status === 200) {
        setSubmitted(true);
        setError("");
      } else {
        setError("Failed to send reset password email. Please try again.");
        setAlertVisible(true);
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
      setAlertVisible(true);
    }
  };

  return (
    <section className="authentication h-100">
      <div className="row align-items-center justify-content-center h-100 w-100">
        <div className="col-md-10 col-lg-9 col-xl-6">
          <div className="card  mx-auto text-center">
            <div className="card-body">
              <div className="mb-3">
                <LogoIcon />
              </div>
              {submitted ? (
                <>
                  <h2 className="my-3 my-md-4">Help is on the way</h2>
                  <p className="text-secondary">
                    We're combing through our records to find the 1Ciso account
                    for:
                    <a href="">{email}</a>
                  </p>
                  <p>
                    If we find a match, you’ll get an email with further
                    instructions. If you don’t hear from us in the next 15
                    minutes, please double check that you entered the correct
                    email address and check your spam folder.
                  </p>
                  <div className="mb-3">
                    <a href="/" type="submit" className="btn btn-link w-100">
                      <i className="bi bi-arrow-left me-2"></i> Back
                    </a>
                  </div>
                </>
              ) : (
                <>
                  <h2 className="my-3 my-md-4">Forgot Password?</h2>
                  <p className="text-secondary">
                    Enter your email to reset your password.
                  </p>
                  <form onSubmit={handleSubmit}>
                    <div className="form-floating mb-3 position-relative input-holder">
                      <input
                        type="email"
                        className="form-control"
                        id="floatingInput"
                        placeholder="name@example.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <label htmlFor="floatingInput">
                        Please enter your email address
                      </label>
                      <span className="position-absolute icon-input">
                        <EnvelopeIcon />
                      </span>
                    </div>
                    <div className="mb-3">
                      <button type="submit" className="btn btn-primary w-100">
                        Submit
                      </button>
                    </div>
                    <p className="text-secondary">{error}</p>
                    <div className="mb-3">
                      <a href="/" className="btn btn-link w-100">
                        <i className="bi bi-arrow-left me-2"></i> Back
                      </a>
                    </div>
                  </form>
                  <Alert
                    message={`${
                      error.length > 1
                        ? error
                        : "Reset password requested successfuly."
                    }`}
                    type={`${error.length > 1 ? "danger" : "success"}`}
                    show={alertVisible}
                    onClose={() => setAlertVisible(false)}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </section>
  );
};

export default ForgotPassword;
