import { useCallback, useEffect, useState } from "react";
import UseCaseCards from "../useCaseCards";
import ChatFooter from "./chatFooter";
import ChatHeader from "./chatHeader";
import Messages from "../messages";
import ChatHistory from "../chatHistory";
import { start_chat } from "../../../utils/endpoints";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/authContext";
import { useChat } from "../../../context/chatContext";
import createAxiosInstance from "../../../utils/axiosConfig";

const ChatBody = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const {
    selectedUseCase,
    selectedSubCase,
    chatId,
    renderFromHistory,
    conversation,
    showCards,
    setSelectedUseCase,
    setSelectedSubCase,
    setChatId,
    setRenderFromHistory,
    setShowCards,
  } = useChat();

  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [isSelecting, setIsSelecting] = useState(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [errorCount, setErrorCount] = useState(0);
  const [messages, setMessages] = useState<
    {
      type: string;
      text: string;
      time: string;
      icon: boolean;
      typewriter?: boolean;
    }[]
  >([]);

  const [eventSource, setEventSource] =
    useState<ReadableStreamDefaultReader | null>(null);

  useEffect(() => {
    if (selectedUseCase !== 0) {
      setMessages([]);
      setErrorCount(0);
      setShowMessage(false);
    }

    return () => {
      if (eventSource) {
        eventSource.cancel();
      }
    };
  }, [selectedUseCase, chatId]);

  useEffect(() => {
    if (!renderFromHistory) {
      if (chatId && selectedUseCase !== 3) {
        if (selectedUseCase == 0) {
          // handleRadioChange(messages[0].text, chatId, "left");
          handleSendMessage(messages[0].text, chatId);
        } else {
          handleRadioChange("hi", chatId, "left");
        }
      }
    } else {
      setMessages([]);
      conversation.conversation?.map((conv: any, index: number) => {
        if (index == 0) {
          addMessage(conv.response, "left");
        } else {
          addMessage(conv.question, "right");
          addMessage(conv.response, "left");
        }
      });
      setRenderFromHistory(false);
    }
  }, [chatId]);

  const handleSelect = async (index: number) => {
    if (isSelecting) return;
    setIsSelecting(true);

    try {
      const response = await createAxiosInstance().post(start_chat);
      if (response.status == 401 || response.status == 403) {
        // 401 is for expired token
        // 403 is for forbidden access (blocked by GPT.)
        console.log("use token expired");
        logout();
        navigate("/");
        return;
      }
      setSelectedUseCase(index);
      setSelectedSubCase(undefined);
      setShowCards(false);
      setRenderFromHistory(false);
      // setMessages([]);
      setChatId(response?.data?.chat_id);
    } catch (error: any) {
      console.error("Error selecting use case:", error);
      if (error?.response?.status == 401 || error?.response?.status == 403) {
        console.log("use token expired");
        logout();
        navigate("/");
        return;
      }
    }finally {
      setIsSelecting(false);
    }
  };

  const canShowChatHistory = () => {
    return selectedUseCase >= 1;
  };

  const addMessage = useCallback((text: string, type: string) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        type: type,
        text,
        time: new Date().toLocaleTimeString(),
        icon: false,
      },
    ]);
  }, []);

  const addToLastMessage = useCallback((text: string, type: string) => {
    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages];
      const lastMessage = updatedMessages[updatedMessages.length - 1];
      // Create a new message object
      const newMessage = {
        ...lastMessage,
        text: lastMessage.text + text,
        time: new Date().toLocaleTimeString(),
        typewriter: true,
        type,
      };
      // Replace the last message with the new one
      updatedMessages[updatedMessages.length - 1] = newMessage;
      return updatedMessages;
    });
  }, []);


  const handleRadioChange = async (
    label: string,
    chat_id: string,
    type: string,
    subCase?: string
  ) => {
    // set payload
    const payload = {
      question: label, // Update with the actual question
      filter: {},
      conversation_id: chat_id,
      // label: `use_case_0`,
      label: `use_case_${selectedUseCase}${
        selectedUseCase === 3 && subCase ? `.${subCase}` : ""
      }`,
    };

    // set payload end...

    // check if chat ID is set or show area of interest message...
    if (!chat_id) {
      setShowCards(false);
      setShowMessage(true);
      setTimeout(() => {
        addMessage("Select one of the following Areas of interest:", "left");
      }, 500);
      setDisabled(false);
      return;
    }

    // check user permissions and try sending message...

    try {
      const token = localStorage.getItem("token"); // Get the token from local storage
      if (!token) {
        navigate("/");
      }

      // Send POST request and handle streaming response
      const response = await fetch(
        "https://chat.1ciso.io/backend/chat/chat",
        // "http://localhost:8000/chat/chat",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Include the token if needed
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.status == 401) {
        logout();
        navigate("/");
        return;
      }

      if (!response.ok) {
        addMessage(
          "An error occurred while processing your request. Please try again.",
          "error"
        );
        setErrorCount(errorCount + 1);
        setDisabled(false);
        return;
      }

      if (response.body) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let buffer = "";
        let text = "";
        let done;
        while (!done) {
          const { done, value } = await reader.read();
          if (done) {
            break;
          }

          buffer += decoder.decode(value, { stream: true });
          console.log(buffer);
          // Split buffer by newline characters
          const lines = buffer.split("\n");
          buffer = lines.pop() || ""; // Keep the last partial line in buffer

          // Process complete lines
          lines.forEach((line) => {
            if (line.startsWith("data: ")) {
              const dataString = line.substring(6); // Remove 'data: ' prefix
              try {
                const data = JSON.parse(dataString);
                if (data.message) {
                  console.log(data.message, "message");
                  text += data.message + "\n\t";
                }
              } catch (e) {
                console.error("Error parsing JSON:", e);
                setErrorCount(errorCount + 1);
                setDisabled(false);
                return;
              }
            }
          });
          // Handle the event data
        }

        addMessage(text, type);
        setDisabled(false);
      }
    } catch (error: any) {
      setErrorCount(errorCount + 1);
      console.error("Error sending request or handling response:", error);
      // Provide user feedback
      addMessage(
        "An error occurred while processing your request. Please try again.",
        "error"
      );
      setDisabled(false);

      if (error?.response?.status == 401) {
        console.log("use token expired");
        logout();
        navigate("/");
        return;
      }

      // Optionally, you can implement more specific error handling logic here
      if (error instanceof TypeError) {
        // Handle network errors (e.g., network down, CORS issues)
        console.error("Network error:", error);
        return;
      } else {
        // Handle other types of errors
        console.error("Unexpected error:", error);
        return;
      }
    }
  };

  const handleSetDisabled = (param: boolean) => {
    setDisabled(param);
  };

  const handleSendMessage = async (label: string, chat_id: string) => {
    const payload = {
      question: label, // Update with the actual question
      filter: {},
      conversation_id: chat_id,
      label: `use_case_${selectedUseCase}`,
    };

    if (selectedUseCase === 3) {
      payload.label = `use_case_${selectedUseCase}${selectedSubCase ? `.${selectedSubCase}` : ""}`;
    } else {
      payload.label = `use_case_${selectedUseCase}`;
    }

    // check if chat ID is set or show area of interest message...
    // if (!chat_id) {
    //   setShowCards(false);
    //   setShowMessage(true);
    //   setTimeout(() => {
    //     addMessage("Select one of the following Areas of interest:", "left");
    //   }, 500);
    //   setDisabled(false);
    //   return;
    // }

    if (!chat_id) {
      setShowCards(false);
      // setShowMessage(true);
      handleSelect(0);
      // addMessage(messages[0].text, messages[0].type);
      return;
    }

    // check user permissions and try sending message...
    try {
      handleSetDisabled(true);
      addMessage("", "left");

      // Close previous event source if it exists
      if (eventSource) {
        eventSource.cancel();
      }
      const token = localStorage.getItem("token");
      // Send POST request and handle streaming response
      const response = await fetch("https://chat.1ciso.io/backend/chat/chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        console.error(`HTTP error! Status: ${response.status}`);
        addMessage(
          "An error occurred while processing your request. Please try again.",
          "error"
        );
        setErrorCount(errorCount + 1);
        setDisabled(false);
        return;
      }

      if (response.body) {
        const reader = response.body.getReader();
        setEventSource(reader);
      }
    } catch (error) {
      setErrorCount(errorCount + 1);
      console.error("Error sending request or handling response:", error);
      // Provide user feedback
      addMessage(
        "An error occurred while processing your request. Please try again.",
        "error"
      );
      setDisabled(false);

      // More specific error handling logic here
      if (error instanceof TypeError) {
        // Handle network errors (e.g., network down, CORS issues)
        console.error("Network error:", error);
        return;
      } else {
        // Handle other types of errors
        console.error("Unexpected error:", error);
        return;
      }
    }
  };

  useEffect(() => {
    if (eventSource) {
      const readStream = async () => {
        try{
          while (true) {
            const { done, value } = await eventSource.read();
            if (done) {
              break;
            }
  
            const buffer = new TextDecoder().decode(value, { stream: true });
            // Split buffer by newline characters
            const lines = buffer.split("\n");
            // buffer = lines.pop() || ""; // Keep the last partial line in buffer
  
            // Process complete lines
            lines.forEach((line) => {
              if (line.startsWith("data: ")) {
                const dataString = line.substring(6); // Remove 'data: ' prefix
                try {
                  const data = JSON.parse(dataString);
                  if (data.message) {
                    addToLastMessage(data.message, "left");
                  }
                } catch (e) {
                  console.error("Error parsing JSON:", e);
                  setErrorCount(errorCount + 1);
                  return;
                }
              }
            });
          }
        }catch(e){
          console.error("Error reading stream:", e);
          if(selectedUseCase == 3 && !selectedSubCase){
            addToLastMessage("Before proceeding furhter, please choose a path first.", "error");
          }else{
            addToLastMessage("An Error occured while processing your request. Kindly refresh the page and try again.", "error");
          }
        }finally{
          setEventSource(null);
        }
       
      };
      readStream();
    }
  }, [eventSource]);

  return (
    <>
      <ChatHeader useCase={selectedUseCase} selectUseCase={handleSelect} />
      {showCards ? (
        <main className="d-flex flex-column h-100">
          <section className="homemain d-flex justify-content-center h-100 align-items-center">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-10 mb-1">
                  <h1 className="mb-md-5 mb-4 text-center text-blue">
                    How can I help you today?
                  </h1>
                  <UseCaseCards selectUseCase={handleSelect} />
                </div>
              </div>
            </div>
          </section>
        </main>
      ) : (
        <Messages
          useCase={selectedUseCase}
          handleSubCase={setSelectedSubCase}
          selectedSubCase={selectedSubCase}
          messages={messages}
          chat_id={chatId}
          handleOptionSelect={handleRadioChange}
          showMessage={showMessage}
          handleUseCaseSelect={handleSelect}
          setDisabled={(param: boolean) => setDisabled(param)}
        />
      )}

      <ChatFooter
        canShowHistory={canShowChatHistory()}
        addMessage={addMessage}
        chat_id={chatId}
        getResponse={handleSendMessage}
        disabled={disabled}
        setDisabled={setDisabled}
        // getHistory={fetchConversations}
      />
      <ChatHistory
        onNavigate={() => {
          navigate("/");
        }}
      />
    </>
  );
};

export default ChatBody;
