// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* For WebKit-based browsers (Chrome, Safari) */
/* ::-webkit-scrollbar { */
/* width: 9px; Adjust width of the scrollbar */
/* height: 9px; Adjust height of the scrollbar */
/* padding-right: 10px;
} */

/* ::-webkit-scrollbar-thumb { */
/* background-color: #ebe6e3; Change the color of the scrollbar thumb */
/* border-radius: 10px; Add a radius to the scrollbar thumb */
/* } */

/* ::-webkit-scrollbar-track {
  background-color: #ffff; /* Change the background of the scrollbar track */
/* border-radius: 10px; Add a radius to the scrollbar track 
} */

/* Firefox-specific styles */
/* html {
  /* scrollbar-width: thin; */
/* scrollbar-color: #e22020 #f1f1f1;  
} */
`, "",{"version":3,"sources":["webpack://./src/assets/scss/scrollbar.css"],"names":[],"mappings":"AAAA,+CAA+C;AAC/C,0BAA0B;AAC1B,8CAA8C;AAC9C,gDAAgD;AAChD;GACG;;AAEH,gCAAgC;AAChC,uEAAuE;AACvE,6DAA6D;AAC7D,MAAM;;AAEN;4EAC4E;AAC5E;GACG;;AAEH,4BAA4B;AAC5B;6BAC6B;AAC7B;GACG","sourcesContent":["/* For WebKit-based browsers (Chrome, Safari) */\n/* ::-webkit-scrollbar { */\n/* width: 9px; Adjust width of the scrollbar */\n/* height: 9px; Adjust height of the scrollbar */\n/* padding-right: 10px;\n} */\n\n/* ::-webkit-scrollbar-thumb { */\n/* background-color: #ebe6e3; Change the color of the scrollbar thumb */\n/* border-radius: 10px; Add a radius to the scrollbar thumb */\n/* } */\n\n/* ::-webkit-scrollbar-track {\n  background-color: #ffff; /* Change the background of the scrollbar track */\n/* border-radius: 10px; Add a radius to the scrollbar track \n} */\n\n/* Firefox-specific styles */\n/* html {\n  /* scrollbar-width: thin; */\n/* scrollbar-color: #e22020 #f1f1f1;  \n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
