import React from "react";
import { JSX } from "react/jsx-runtime";

const LogoIcon = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_373_18095)">
        <path
          d="M67.177 47.0729C67.177 51.3601 64.8898 55.3216 61.177 57.4652L42.0001 68.537C38.2873 70.6806 33.7129 70.6806 30.0001 68.537L10.8232 57.4652C7.11036 55.3216 4.82317 51.3601 4.82317 47.0729L4.82317 24.9293C4.82317 20.6421 7.11036 16.6806 10.8232 14.537L30.0001 3.46523C33.7129 1.32164 38.2873 1.32164 42.0001 3.46523L61.177 14.537C64.8898 16.6806 67.177 20.6421 67.177 24.9293L67.177 47.0729Z"
          fill="#B1D4FC"
        />
        <path
          d="M56.5598 59.6113C54.4162 63.3241 50.4547 65.6113 46.1675 65.6113L25.8325 65.6113C21.5453 65.6113 17.5838 63.3241 15.4402 59.6113L5.27271 42.0007C3.12912 38.2879 3.12912 33.7135 5.27272 30.0007L15.4402 12.3901C17.5838 8.67729 21.5453 6.39011 25.8325 6.39011L46.1675 6.3901C50.4547 6.3901 54.4162 8.67729 56.5598 12.3901L66.7273 30.0007C68.8709 33.7135 68.8709 38.2879 66.7273 42.0007L56.5598 59.6113Z"
          fill="#9ECBFF"
        />
        <rect
          x="37.4417"
          y="24.0664"
          width="7.6017"
          height="22.8051"
          rx="3.80085"
          transform="rotate(25.3239 37.4417 24.0664)"
          fill="#1D387D"
        />
        <rect
          x="22.0735"
          y="21"
          width="7.6017"
          height="15.2034"
          rx="3.80085"
          transform="rotate(25.3239 22.0735 21)"
          fill="#1D387D"
        />
        <rect
          x="49.5583"
          y="34.0078"
          width="7.6017"
          height="15.2034"
          rx="3.80085"
          transform="rotate(25.3239 49.5583 34.0078)"
          fill="#1D387D"
        />
      </g>
      <defs>
        <clipPath id="clip0_373_18095">
          <rect width="72" height="72" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoIcon;
