import React, { useState } from "react";
import "../../../assets/scss/spinStyles.css";
import { useChatHistory } from "../../../context/chatHistoryContext";
import { useAuth } from "../../../context/authContext";
import Alert from "../alerts/alert";
interface ChatFooterProps {
  canShowHistory: boolean;
  addMessage: (text: string, type: string) => void;
  chat_id: string;
  getResponse: (label: string, chat_id: string, type: string) => void;
  disabled: boolean;
  setDisabled: any;
  // getHistory: () => void;
}

const ChatFooter: React.FC<ChatFooterProps> = React.memo(
  ({ chat_id, addMessage, getResponse, disabled }) => {
    const { isAuthenticated } = useAuth();
    const { openChatHistory } = useChatHistory();
    const [message, setMessage] = useState("");
    const [alertVisible, setAlertVisible] = useState(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setMessage(e.target.value);
    };

    const handleSend = () => {
      if (message.trim()) {
        addMessage(message, "right");
        setMessage("");
        getResponse(message, chat_id, "left");
      }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter" && message.length > 0 && !disabled) {
        handleSend();
      }
    };

    const handleAllConversationClick = (
      e: React.MouseEvent<HTMLAnchorElement>
    ) => {
      e.preventDefault();
      if (isAuthenticated) {
        openChatHistory();
      } else {
        setAlertVisible(true);
      }
    };

    return (
      <section className="chatfooter mt-auto">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="d-flex gap-3 mb-3">
                <input
                  type="text"
                  className="form-control chatinput"
                  placeholder="Type your message"
                  value={message}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                />
                <button
                  className="btn btn-secondary iconround iconmedium"
                  style={{ background: "#205493 !important" }}
                  onClick={() => handleSend()}
                  disabled={disabled || message.length === 0}
                >
                  <i
                    className={`${
                      disabled
                        ? "bi bi-arrow-clockwise icon-spin"
                        : "bi bi-send"
                    }`}
                  ></i>
                </button>
              </div>
              <div className="row">
                <div className="col-4">
                  <span>
                    <a
                      className={`small ${!isAuthenticated ? "disabled" : ""}`}
                      // data-bs-toggle="offcanvas"
                      // href="#offcanvasExample"
                      role="button"
                      // aria-controls="offcanvasExample"
                      onClick={handleAllConversationClick}
                    >
                      <i className="bi bi-chat-right-dots"></i> All Conversation
                    </a>
                  </span>
                </div>
                <div className="col-4">
                  <span style={{}}>
                    <a className="small" href="/terms-and-conditions">
                      Terms and Conditions
                    </a>
                  </span>
                </div>
                <div className="col-4" style={{ textAlign: "center" }}>
                  <span style={{}}>
                    <a className="small" href="/privacy-policy">
                      Privacy Policy
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Alert
          message="Loads only when logged in."
          type="warning"
          show={alertVisible}
          onClose={() => setAlertVisible(false)}
        />
      </section>
    );
  }
);

export default ChatFooter;
