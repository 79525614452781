import React, { useEffect, useState } from "react";
import LogoIcon from "../../assets/svg/logoIcon";
import { validate } from "../../utils/formValidate";
import { change_password } from "../../utils/endpoints";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/authContext";
import Alert from "../common/alerts/alert";
import createAxiosInstance from "../../utils/axiosConfig";

const ChangePasswordPublic = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState<any>({});
  const [responseError, setResponseError] = useState<any>("");
  const [alertVisible, setAlertVisible] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  if (token) {
    // If a token exists in query parameters, save it to localStorage
    localStorage.setItem("changePasswordToken", token);
  } else {
    // user isn't authenticated already.
    if (!isAuthenticated) {
      // If no token in localStorage, redirect to login
      navigate("/login");
    }
  }

  // safely handle user authentication ...
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    if (token) {
      // If a token exists in query parameters, save it to localStorage
      localStorage.setItem("changePasswordToken", token);
    } else {
      // user isn't authenticated already.
      if (!isAuthenticated) {
        // If no token in localStorage, redirect to login
        navigate("/login");
      }
    }
  }, []);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validate({ [name]: value }, setErrors);
    // Confirm password validation
    if (name === "confirmPassword") {
      if (value !== formData.password) {
        // setConfirmPasswordError("Passwords do not match.");
        setErrors({ ...errors, confirmPassword: "Passwords do not match." });
      } else {
        setErrors({ ...errors, confirmPassword: "" });
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validate(formData, setErrors)) return;
    const email = queryParams.get("email");
    const payload = {
      new_password: formData.password,
      email: email,
    };

    if (!email) {
      return;
    } else {
      payload.email = email;
    }

    try {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.post(change_password, payload);

      if (response.data?.user?.error) {
        setResponseError(response?.data?.user?.error);
        setAlertVisible(true);
      } else {
        // setResponseError(response?.data?.message);
        setAlertVisible(true);
        setFormData({
          password: "",
          confirmPassword: "",
        });
      }
    } catch (err) {
      setResponseError("Some errors occured. Please try again later.");
      setAlertVisible(true);
      console.log(err);
    }
  };

  return (
    <section className="authentication h-100">
      <div className="row align-items-center justify-content-center h-100 w-100">
        <div className="col-md-10 col-lg-9 col-xl-6">
          <div className="card  mx-auto text-center">
            <div className="card-body">
              <div className="mb-3">
                {/* <img src="assets/img/logo-sm.svg" alt=""> */}
                <LogoIcon />
              </div>
              <h2 className="my-3 my-md-4"> Setup New Password</h2>
              <p className="text-secondary">
                Have you already reset the password ? <a href="">Sign in</a>
              </p>
              <form action="" onSubmit={handleSubmit}>
                <div className="form-floating mb-3">
                  <input
                    type="password"
                    className={`form-control ${
                      errors.password ? "is-invalid" : ""
                    }`}
                    id="floatingInput"
                    placeholder="********"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                  <label htmlFor="floatingInput">Password</label>
                  <div className="form-text text-start">
                    <i className="bi bi-info-circle"></i> Use 8 or more
                    characters with a mix of letters, numbers & symbols.
                  </div>
                  {errors.password && (
                    <div className="text-danger small">{errors.password}</div>
                  )}
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="password"
                    className={`form-control ${
                      errors.confirmPassword ? "is-invalid" : ""
                    }`}
                    id="confirm-password"
                    placeholder="********"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                  />
                  <label htmlFor="confirm-password">Confirm Password</label>
                  {errors.confirmPassword && (
                    <div className="text-danger small">
                      {errors.confirmPassword}
                    </div>
                  )}
                </div>
                <div className="form-check  text-start mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="1"
                    name="agreed"
                    id="flexCheckDefault"
                    required
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    I Agree &{" "}
                    <a href="/terms-and-conditions">Terms and conditions</a>
                  </label>
                </div>

                <div className="mb-3">
                  <button type="submit" className="btn btn-primary w-100">
                    Submit
                  </button>
                </div>
                {responseError && <p>{responseError}</p>}
                <div className="mb-3">
                  <a href="/chat" type="submit" className="btn btn-link w-100">
                    <i className="bi bi-arrow-left me-2"></i> Back
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Alert
        message={`${
          responseError.length > 1
            ? responseError
            : "Password reset successfull."
        }`}
        type={`${responseError.length > 1 ? "danger" : "success"}`}
        show={alertVisible}
        onClose={() => setAlertVisible(false)}
      />
    </section>
  );
};

export default ChangePasswordPublic;
