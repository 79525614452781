import React, { useEffect, useState } from "react";
import LogoIcon from "../../assets/svg/logoIcon";
import { useCases } from "../../utils/constants";
import { UseCase } from "../../utils/interfaces";
import axios from "axios";
import { ingest_file } from "../../utils/endpoints";
import { useNavigate } from "react-router-dom";
import BackButton from "../common/backButton";
import Footer from "../common/footer";
import ChatHistory from "../common/chatHistory";
import createAxiosInstance from "../../utils/axiosConfig";
import Alert from "../common/alerts/alert";

type AlertType =
  | "danger"
  | "success"
  | "primary"
  | "secondary"
  | "warning"
  | "info"
  | "light"
  | "dark";

const IngestData = () => {
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userData") || "{}");

  const [listFiles, setListFiles] = useState<any[]>([]);
  const [errors, setErrors] = useState<any>({});
  const [responseError, setResponseError] = useState<string>("");
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState<AlertType>("success");

  const [formData, setFormData] = useState({
    use_case: "",
    base_path: "",
    requires_enrichment: false,
  });

  const [ingestions, setIngestions] = useState<any[]>([]);

  const validate = (formData: any, setErrors: (errors: any) => void) => {
    const tempErrors: any = {};

    Object.keys(formData).forEach((key) => {
      if (!formData[key as keyof typeof formData]) {
        if (
          formData?.requires_enrichment == false ||
          formData?.requires_enrichment == true
        ) {
          return;
        } else {
          tempErrors[key] = `${key} is required`;
        }
      }
    });

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validate({ [name]: value }, setErrors);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validate(formData, setErrors)) return;

    const payload = {
      use_case: `use_case_${formData.use_case}`,
      base_path: formData.base_path,
      requires_enrichment: formData.requires_enrichment,
    };

    try {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.post(ingest_file, payload);
      if (response.data?.error) {
        setResponseError(response?.data?.error);
        setAlertVisible(true);
        setAlertType("danger");
      } else {
        setResponseError("Ingestion started successfully...!");
        setAlertVisible(true);
        setAlertType("success");
        setFormData({
          use_case: "",
          base_path: "",
          requires_enrichment: false,
        });

        setIngestions([
          ...ingestions,
          {
            name: `${formData?.base_path?.split("/data/")[1]}`,
            process_id: response?.data?.process_id,
            status: "pending",
          },
        ]);

        let updatedData = [
          ...ingestions,
          {
            name: `${formData?.base_path?.split("/data/")[1]}`,
            process_id: response?.data?.process_id,
            status: "pending",
            timestamp: Date.now(),
          },
        ];

        localStorage.setItem("ingestionData", JSON.stringify(updatedData));
      }
    } catch (err) {
      setResponseError("Some errors occured. Please try again later.");
      setAlertVisible(true);
      setAlertType("danger");
      console.log(err);
    }
  };

  const getFilesList = async () => {
    try {
      const axiosInstance = createAxiosInstance({
        "Content-Type": "multipart/form-data",
      });

      const response = await axiosInstance.get("/ingest/list_files");
      setListFiles(response?.data?.files);
    } catch (error) {
      console.error("Error uploading file:", error);
      setResponseError("Error listing files. Try again later.");
      setAlertVisible(true);
      setAlertType("danger");
    }
  };

  const fetchIngestionData = () => {
    const storedData = localStorage.getItem("ingestionData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      const filteredData = parsedData.filter(
        (item: { timestamp: number }) =>
          Date.now() - item.timestamp <= 48 * 60 * 60 * 1000
      );
      setIngestions(filteredData);
    }
  };

  useEffect(() => {
    getFilesList();
    if (!userData?.is_admin || userData?.is_admin == 0) {
      navigate("/");
    }
    fetchIngestionData();
  }, []);

  const checkStatus = async (process_id: string) => {
    try {
      const axiosInstance = createAxiosInstance({
        "Content-Type": "multipart/form-data",
      });

      const response = await axiosInstance.get(
        "/ingest/check_process/" + process_id
      );
      // alert(`Status: ${response?.data?.status}`);

      if (response?.data?.status == "SUCCESS") {
        setResponseError(`Status: ${response?.data?.status}`);
        setAlertVisible(true);
        setAlertType("success");
        const storedData = localStorage.getItem("ingestionData");
        if (storedData) {
          const parsedData = JSON.parse(storedData);

          // Filter out the object with the matching process_id
          const updatedData = parsedData.filter(
            (item: { process_id: string }) => item.process_id !== process_id
          );

          // Save the updated array back to local storage
          localStorage.setItem("ingestionData", JSON.stringify(updatedData));

          // Update the state to reflect the changes
          setIngestions(updatedData);
        }
      } else {
        if (response?.data?.status !== "PENDING") {
          setResponseError(`Status: ${response?.data?.status}`);
          setAlertVisible(true);
          setAlertType("danger");
          // status is not success and not pending...! means it failed
          const storedData = localStorage.getItem("ingestionData");
          if (storedData) {
            const parsedData = JSON.parse(storedData);

            // Filter out the object with the matching process_id
            const updatedData = parsedData.filter(
              (item: { process_id: string }) => item.process_id !== process_id
            );

            // Save the updated array back to local storage
            localStorage.setItem("ingestionData", JSON.stringify(updatedData));

            // Update the state to reflect the changes
            setIngestions(updatedData);
          }
        } else {
          // status is pending
          setResponseError(`Status: ${response?.data?.status}`);
          setAlertVisible(true);
          setAlertType("info");
        }
      }
    } catch (error) {
      console.error("Error confirming ingestion status:", error);
      // alert(`Wrong file or ingestion has failed.`);
      setResponseError("Wrong file or ingestion has failed.");
      setAlertVisible(true);
      setAlertType("danger");
      // remove the ingestion from the list
      const storedData = localStorage.getItem("ingestionData");
      if (storedData) {
        const parsedData = JSON.parse(storedData);

        // Filter out the object with the matching process_id
        const updatedData = parsedData.filter(
          (item: { process_id: string }) => item.process_id !== process_id
        );

        // Save the updated array back to local storage
        localStorage.setItem("ingestionData", JSON.stringify(updatedData));

        // Update the state to reflect the changes
        setIngestions(updatedData);
      }
    }
  };

  return (
    <section className="authentication h-100">
      <div className="row align-items-center justify-content-center h-100 w-100">
        <div className="col-md-10 col-lg-9 col-xl-6">
          <div className="card  mx-auto text-center">
            <div className="card-body">
              <div className="mb-3">
                <LogoIcon />
              </div>
              <h2 className="my-3 my-md-4"> Ingest Uploaded Files </h2>
              {/* <p className="text-secondary">
                Have you already uploaded all new files ?{" "}
                <a href="">Ingest Data</a>
              </p> */}
              <form action="" onSubmit={handleSubmit}>
                <div className="form-floating mb-3">
                  <select
                    name="use_case"
                    id=""
                    className={`form-select ${
                      errors.use_case ? "is-invalid" : ""
                    }`}
                    value={formData.use_case}
                    onChange={handleChange}
                  >
                    <option>Select a use case</option>
                    {useCases.map((useCase: UseCase, key) => (
                      <option
                        value={useCase.value}
                        key={`use_case_options_${key}`}
                        selected={useCase.value == Number(useCase)}
                      >
                        {useCase.name}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="floatingSelect">Use Case</label>
                  {errors.use_case && (
                    <div className="text-danger small">{errors.use_case}</div>
                  )}
                </div>

                <div className="form-floating mb-3 ">
                  <select
                    className={`form-select ${
                      errors.base_path ? "is-invalid" : ""
                    }`}
                    id="floatingSelect"
                    aria-label="Floating label select example"
                    name="base_path"
                    value={formData.base_path}
                    onChange={handleChange}
                  >
                    <option>Select a file</option>
                    {listFiles?.map((file) => {
                      return (
                        <option value={file}>{file.split("/data/")[1]}</option>
                      );
                    })}
                  </select>
                  <label htmlFor="floatingSelect">File</label>
                  {errors.base_path && (
                    <div className="text-danger small">{errors.base_path}</div>
                  )}
                </div>

                <div className="form-check  text-start mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value="1"
                    name="agreed"
                    id="flexCheckDefault"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    Requires Enrichment{" "}
                  </label>
                </div>

                <div className="mb-3">
                  <button
                    type="submit"
                    className="btn btn-primary w-100"
                    //   onClick={handleUpload}
                    disabled={Object.keys(errors).length > 0}
                  >
                    Submit
                  </button>
                </div>
                <BackButton />
              </form>

              <div className="container">
                {ingestions.map((ingestion) => {
                  return (
                    <div className="row mb-4">
                      <div className="col-sm-8 pt-1">{ingestion.name}</div>
                      <div className="col-sm-4">
                        <button
                          className="btn btn-primary"
                          onClick={() => checkStatus(ingestion?.process_id)}
                        >
                          check status{" "}
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>

      <ChatHistory
        onNavigate={() => {
          navigate("/");
        }}
      />
      <Alert
        message={`${responseError}`}
        type={alertType}
        show={alertVisible}
        onClose={() => setAlertVisible(false)}
      />
    </section>
  );
};

export default IngestData;
