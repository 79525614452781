import React, { createContext, useState, useContext } from "react";
import { useAuth } from "./authContext";

interface ChatContextType {
  selectedUseCase: number;
  selectedSubCase: string | undefined;
  chatId: string;
  renderFromHistory: boolean;
  conversation: any;
  showCards: boolean;
  setSelectedUseCase: (useCase: number) => void;
  setSelectedSubCase: (subCase: string | undefined) => void;
  setChatId: (id: string) => void;
  setRenderFromHistory: (render: boolean) => void;
  setConversation: (conversation: any) => void;
  setShowCards: (show: boolean) => void;
  resumeChat: (conversation: any, navigateCallback: () => void) => void;
  resetChatState: () => void;
}

const ChatContext = createContext<ChatContextType | undefined>(undefined);

export const ChatProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isAuthenticated } = useAuth();
  const [selectedUseCase, setSelectedUseCase] = useState<number>(0);
  const [selectedSubCase, setSelectedSubCase] = useState<string | undefined>();
  const [chatId, setChatId] = useState<string>("");
  const [renderFromHistory, setRenderFromHistory] = useState<boolean>(false);
  const [conversation, setConversation] = useState<any>(null);
  const [showCards, setShowCards] = useState<boolean>(true);


  const resetChatState = () => {
    setSelectedUseCase(0);
    setSelectedSubCase(undefined);
    setChatId("");
    setRenderFromHistory(false);
    setConversation(null);
    setShowCards(true);
  };

  React.useEffect(() => {
    if (!isAuthenticated) {
      resetChatState();
    }
  }, [isAuthenticated]);


  const resumeChat = (conversation: any, navigateCallback: () => void) => {
    let label = conversation?.conversation[0]?.label.split("_");
    let useCase = Number(label[label.length - 1]);
    let subCase = "";
    if (label[label.length - 1].includes(".")) {
      const useCaseParts = label[label.length - 1].split(".");
      useCase = Number(useCaseParts[0]);
      subCase = useCaseParts[1];
      setSelectedSubCase(subCase);
    }
    setSelectedUseCase(useCase);
    setChatId(conversation?.id);
    setRenderFromHistory(true);
    setConversation(conversation);
    setShowCards(false);

    // Call the navigate callback
    navigateCallback();
  };

  return (
    <ChatContext.Provider
      value={{
        selectedUseCase,
        selectedSubCase,
        chatId,
        renderFromHistory,
        conversation,
        showCards,
        setSelectedUseCase,
        setSelectedSubCase,
        setChatId,
        setRenderFromHistory,
        setConversation,
        setShowCards,
        resumeChat,
        resetChatState,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error("useChat must be used within a ChatProvider");
  }
  return context;
};
