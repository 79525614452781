import Footer from "../common/footer";
import ContactForm from "../common/forms/contact";
import BackButton from "../common/backButton";
import ChatHistory from "../common/chatHistory";
import { useNavigate } from "react-router-dom";

const ContactSales = () => {
  const navigate = useNavigate();
  return (
    <section className="homemain ">
      <div className="container">
        <div className="row justify-content-center mt-5 pt-5">
          <div className="col-md-9">
            <article className=" text-center mb-md-5 mb-3 ">
              <h1 className="mb-md-3 mb-2 text-blue">Contact Sales</h1>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <i className="bi bi-star-fill text-warning fs-5"></i>
                </li>
                <li className="list-inline-item">
                  <i className="bi bi-star-fill text-warning fs-5"></i>
                </li>
                <li className="list-inline-item">
                  <i className="bi bi-star-fill text-warning fs-5"></i>
                </li>
                <li className="list-inline-item">
                  <i className="bi bi-star-fill text-warning fs-5"></i>
                </li>
                <li className="list-inline-item">
                  <i className="bi bi-star-fill text-warning fs-5"></i>
                </li>
              </ul>
              <p className="text-secondary">
                Thank you for your interest, please fill out the form and our
                sales team will contact you.
              </p>
            </article>
            <div>
              <ContactForm type={"sales"} />
            </div>
          </div>
          <BackButton />
        </div>
      </div>
      <Footer />
      <ChatHistory
        onNavigate={() => {
          navigate("/");
        }}
      />
    </section>
  );
};

export default ContactSales;
