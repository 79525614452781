// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

/* Style for the button */
.btn-custom {
  cursor: pointer;
  border: none;
  /* background-color: "#205493 !important"; */
  padding: 8px 16px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* Style for the icon */
.icon {
  font-size: 20px;
}

/* Add the spin animation when the icon is spinning */
.icon-spin {
  animation: spin 1s linear infinite;
}
`, "",{"version":3,"sources":["webpack://./src/assets/scss/spinStyles.css"],"names":[],"mappings":"AAAA;EACE;IACE,yBAAyB;EAC3B;AACF;;AAEA,yBAAyB;AACzB;EACE,eAAe;EACf,YAAY;EACZ,4CAA4C;EAC5C,iBAAiB;EACjB,kBAAkB;EAClB,oBAAoB;EACpB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA,uBAAuB;AACvB;EACE,eAAe;AACjB;;AAEA,qDAAqD;AACrD;EACE,kCAAkC;AACpC","sourcesContent":["@keyframes spin {\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n/* Style for the button */\n.btn-custom {\n  cursor: pointer;\n  border: none;\n  /* background-color: \"#205493 !important\"; */\n  padding: 8px 16px;\n  border-radius: 4px;\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n}\n\n/* Style for the icon */\n.icon {\n  font-size: 20px;\n}\n\n/* Add the spin animation when the icon is spinning */\n.icon-spin {\n  animation: spin 1s linear infinite;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
