export const validate = (formData: any, setErrors: (errors: any) => void) => {
  const tempErrors: any = {};
  const passwordPattern =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  Object.keys(formData).forEach((key) => {
    if (!formData[key as keyof typeof formData]) {
      const fieldName = key.split("_").map((fieldName) => {
        return `${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)} `;
      });

      tempErrors[key] = `${fieldName[0]} ${
        fieldName.length > 1 ? fieldName[1] : ""
      } field is required`;
    }
  });

  if (formData.password && !passwordPattern.test(formData.password)) {
    tempErrors.password =
      "Password must be 8 or more characters with a mix of letters, numbers & symbols.";
  }

  // if (
  //   formData.confirmPassword !== undefined &&
  //   formData.password !== formData.confirmPassword
  // ) {
  //   tempErrors.confirmPassword = "Passwords do not match.";
  // }

  setErrors(tempErrors);
  return Object.keys(tempErrors).length === 0;
};
