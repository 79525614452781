import React, { createContext, useState, useContext } from "react";
import createAxiosInstance from "../utils/axiosConfig";

interface ChatHistoryContextType {
  isOpen: boolean;
  conversations: any[];
  loading: boolean;
  openChatHistory: () => void;
  closeChatHistory: () => void;
  fetchConversations: () => Promise<void>;
}

const ChatHistoryContext = createContext<ChatHistoryContextType | undefined>(
  undefined
);

export const ChatHistoryProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(false);

  const openChatHistory = () => {
    setIsOpen(true);
    fetchConversations();
  };

  const closeChatHistory = () => {
    setIsOpen(false);
  };

  const fetchConversations = async () => {
    if (!loading) {
      setLoading(true);
      try {
        const axiosInstance = createAxiosInstance();
        const response = await axiosInstance.get("/chat/history");
        setConversations(response.data);
      } catch (error) {
        console.error("Error fetching conversations:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <ChatHistoryContext.Provider
      value={{
        isOpen,
        conversations,
        loading,
        openChatHistory,
        closeChatHistory,
        fetchConversations,
      }}
    >
      {children}
    </ChatHistoryContext.Provider>
  );
};

export const useChatHistory = () => {
  const context = useContext(ChatHistoryContext);
  if (context === undefined) {
    throw new Error("useChatHistory must be used within a ChatHistoryProvider");
  }
  return context;
};
