import React from "react";
import BackButton from "../common/backButton";
import Footer from "../common/footer";
import { useNavigate } from "react-router-dom";
import ChatHistory from "../common/chatHistory";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  return (
    <section className="authentication h-100">
      <div className="row align-items-center justify-content-center h-100 w-100">
        <div className="col-md-10 col-lg-9 col-xl-6">
          <div className="card  mx-auto ">
            <div className="text-start">
              <BackButton />
            </div>

            <div className="card-body">
              <h1>Privacy Policy</h1>

              <p className="text-secondary">
                1CISO.io Inc. ("1CISO.io", "Company", "we", "us", "our") takes
                privacy seriously. We are committed to protecting your privacy
                through our compliance with this Privacy Policy ("the Policy").
              </p>

              <p className="text-secondary">This Policy describes:</p>
              <ol>
                <li>
                  <p>
                    The types of information we may collect from users when you
                    visit our website{" "}
                    <a href="https://1ciso.io/">www.1CISO.io</a>, its subdomains
                    that are owned and operated by 1CISO.io, including but not
                    limited to 1CISO.io.com/app (collectively, the "Website") or
                    that you may provide when you interact, register with,
                    access, or use any The 1CISO.io services (collectively, the
                    "Services").
                  </p>
                </li>
                <li>
                  <p>
                    Our practices for collecting, using, maintaining,
                    protecting, and disclosing that information.
                  </p>
                </li>
              </ol>

              <h3>About 1CISO.io</h3>
              <p className="text-secondary">
                1CISO.io offers a peer review and information sharing platform
                for cybersecurity professionals, where community members
                ("users") can search for and share open-source intelligence
                information, and contribute ratings and reviews about such
                information.
              </p>

              <p className="text-secondary">
                This Policy applies to information we collect:
              </p>
              <div style={{ paddingLeft: 18, paddingBottom: 12 }}>
                <ul style={{ listStyleType: "disc" }}>
                  <li>On this Website;</li>
                  <li>
                    In email, text, and other electronic messages between you
                    and 1CISO.io;
                  </li>
                  <li>
                    Through the Website and other electronic communications sent
                    through or in connection with the Website and Services;
                  </li>
                </ul>
              </div>

              <p className="text-secondary">
                Information We Collect and How We Collect It
              </p>
              <p className="text-secondary">
                We receive, store, and process certain types of personal
                information about you from your use of the Site.
              </p>
              <p className="text-secondary">
                What Categories and Types of Information We Collect
              </p>
              <p className="text-secondary">
                We may collect, transmit and store information about you if you
                are a registered user of our Site. We collect various categories
                of personal information when you use the Site:
              </p>

              <div className="table-container">
                <table>
                  <thead>
                    <tr>
                      <th>Category</th>
                      <th>Examples</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Identifiers</td>
                      <td>First Name, Last Name, Email address.</td>
                    </tr>
                    <tr>
                      <td>Usage or Device Activity Information</td>
                      <td>
                        Search history, information on your interactions with
                        the site, IP address
                      </td>
                    </tr>
                    <tr>
                      <td>Professional Information</td>
                      <td>Current or past employment, job title, employer</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <h3>How We Receive Personal Information</h3>
              <p className="text-secondary">
                Directly from you. When you log-in as a registered user, we will
                collect and store that information.
              </p>
              <p className="text-secondary">
                Indirectly from you. When you navigate and interact with our
                Site, some information is automatically collected by cookies and
                similar technologies. Not all information collected may be
                personal information, and some cookies are necessary to use
                different functionalities of our Site. The personal information
                collected may include information about your computer and
                internet connection, like IP address, operating system, and
                browser type.
              </p>

              <p className="text-secondary">
                From Social Media Networks. When you use a social network login
                to access our Site, you will share certain personal information
                from your social media account with us. For example, if you
                connect to our Site through your LinkedIn account, we will
                receive and store your LinkedIn Profile information (your
                company name, industry, company size, job title, summary, end
                date, profile picture, and skills from your LinkedIn profile)
                and LinkedIn connections (we will use this information to show
                you ratings/reviews written by your connections, however, we
                will not access your connections' contact information nor send
                messages to your connections).
              </p>

              <p className="text-secondary">
                This Website uses Google Analytics, a web analytics service
                provided by Google, Inc. ("Google"). Google Analytics uses
                "cookies," which are text files placed on your computer, to help
                the Website analyze how users use the site. The information
                generated by the cookie about your use of the Website (including
                your IP address) will be transmitted to and stored by Google on
                servers in the United States. Google will use this information
                for the purpose of evaluating your use of the Website, compiling
                reports on website activity for website operators, and providing
                other services relating to website activity and internet usage.
                Google may also transfer this information to third parties where
                required to do so by law, or where such third parties process
                the information on Google's behalf. Google will not associate
                your IP address with any other data held by Google. You may
                refuse the use of cookies by selecting the appropriate settings
                on your browser, however please note that if you do this you may
                not be able to use the full functionality of this Website. By
                using this Website, you consent to the processing of data about
                you by Google in the manner and for the purposes set out above.
              </p>

              <p className="text-secondary">
                This Website uses "cookie" technology to obtain non-Personal
                Information from online visitors. We don't extract Personal
                Information in this process. Rather, cookies allow us to update
                and improve our Website. Even if you set your browser not to
                accept cookies, you can still use most of the features of the
                Website.
              </p>

              <h3>How We Use Your Personal Information</h3>

              <p className="text-secondary">
                We do not sell your Personal Information. We may use or disclose
                the Personal Information we collect for one or more of the
                purposes listed in the chart above.
              </p>

              <p className="text-secondary">
                We will not collect additional categories of Personal
                Information or use the Personal Information we collected for
                materially different, unrelated, or incompatible purposes
                without providing you notice.
              </p>

              <p className="text-secondary">
                We may use Personal Information as permitted by law, for the
                following business purposes:
              </p>
              <p className="text-secondary">
                To provide the Site and various functions related to services
                offered on the Site. When you create a registered account on the
                Site, we store and use the information you provide during that
                process. This may include your first and last name, your email
                address, the company you work for, and any other information you
                may provide during the account creation process. By creating a
                registered login with the Site, you are consenting for The
                1CISO.io to use your personal data in the following manner:
                publicly display your first name and last name, as well as any
                other photo or content you supply as part of your account
                profile.
              </p>

              <p className="text-secondary">
                Any ratings/reviews or other content that you submit to the Site
                are intended for public display and are therefore viewable by
                other community members ("users"), including your first name and
                last name, and any photos associated with your account. You can
                limit the public nature of some of this information in your
                account settings.
              </p>
              <p className="text-secondary">
                To engage in research and analysis related to the Site. We store
                and use information to identify and fix errors, understand how
                visitors use our Site, research and test different features, and
                analyze how users use our Site.
              </p>

              <p className="text-secondary">
                To personalize your experience. We store and use information
                provided by you, such as your role or industry to personalize
                and make recommendations to you based on your provided
                information.
              </p>

              <p className="text-secondary">
                To improve and understand usage of the Site. We store and use
                information about your use of the Site, such as your search
                activity, pages viewed, and the date, time and duration of your
                visits, to understand and improve usage of the Site.
              </p>

              <p className="text-secondary">
                To enhance the security of the Site. We store and use
                information to ensure the security of our Site. For example, we
                use the information collected by certain cookies to determine if
                you are logged in and to detect unauthorized access to your
                account.
              </p>

              <p className="text-secondary">
                To comply with legal obligations. We use and share your
                information as necessary to respond to subpoenas, court orders,
                or other legal processes; we also may use and share your
                information to establish or exercise our legal rights, to defend
                against legal claims, or as otherwise required by law.
              </p>

              <p className="text-secondary">
                We may use and share your personal information when we believe
                it is necessary to investigate, prevent, or take action
                regarding illegal or suspected illegal activities; to protect
                and defend the rights, property, or safety of The 1CISO.io, our
                Site visitors and registered users, and others.
              </p>

              <p className="text-secondary">
                We may use and share your personal information in connection
                with a corporate transaction, such as a divestiture, investment,
                merger, consolidation, or asset sale.
              </p>

              <h3>How We Share Your Information</h3>

              <p className="text-secondary">
                Sharing your content with other users. When you leave a
                rating/review on the Site, the rating/review and any personal
                information you have associated with will only be shared with
                other community members ("users") on the Site.
              </p>

              <p className="text-secondary">
                We will share the following personal information, to the extent
                you provide it:
              </p>

              <div style={{ paddingLeft: 18, paddingBottom: 12 }}>
                <ul style={{ listStyleType: "disc" }}>
                  <li>First name and Last name</li>
                  <li>
                    The profile picture associated with your account on The
                    1CISO.io
                  </li>
                  <li>Your job title</li>
                  <li>Your employer</li>
                </ul>
              </div>

              <p className="text-secondary">
                Sharing with our service providers. 1CISO.io uses third party
                service providers to support or provide services for us in
                connection with the Site, such as hosting, security and fraud
                prevention, technical and user support, tracking and reporting
                usage of the Site, marketing, and other functions. We share your
                information with them as necessary to perform tasks on our
                behalf and they are obligated to not disclose or use it for any
                other purpose.
              </p>

              <p className="text-secondary">
                To enhance the security of the Site. We store and use
                information to ensure the security of our Site. For example, we
                use the information collected by certain cookies to determine if
                you are logged in and to detect unauthorized access to your
                account.
              </p>

              <h3>Your Data Privacy Rights</h3>
              <h3>European Privacy Rights</h3>
              <p className="text-secondary">
                If you are a resident or are otherwise located in the territory
                of Europe or the United Kingdom, this section provides
                additional details about the personal data we collect about you,
                and your rights granted by the EU and UK General Data Protection
                Regulations ("GDPR"). Subject to certain limitations, the GDPR
                provides you the following privacy rights:
              </p>

              <p className="text-secondary">
                Transparency and the right to information. Through this policy
                we explain how we use and share your personal information.
              </p>

              <p className="text-secondary">
                Right of access, objection, restriction of processing,
                correction, erasure, and portability. You can make a request to
                access, object, restrict, correct, erase, or transfer any of
                your personal data. You also have the right to withdraw your
                consent at any time when we process your personal data based on
                your consent.
              </p>

              <p className="text-secondary">
                Right to opt-in and opt-out to direct marketing. By registering
                as a user, you opt-in to receive direct marketing. You have the
                right to opt-out at any time to receive marketing materials from
                us by contacting us.
              </p>

              <p className="text-secondary">
                Please note that we reserve the right to send you other
                communications, including service announcements and
                administrative messages relating to your user account, without
                offering you the opportunity to opt out of receiving them.
              </p>

              <h3>California Privacy Rights</h3>
              <p className="text-secondary">
                If you are a resident of California, this section provides
                additional details about the personal information we collect
                about you, and your rights under the California Consumer Privacy
                Act, or "CCPA."
              </p>

              <p className="text-secondary">
                Subject to certain limitations, the CCPA provides you the right
                to request:
              </p>
              <div style={{ paddingLeft: 18, paddingBottom: 12 }}>
                <ul style={{ listStyleType: "disc" }}>
                  <li>
                    That we provide you access to details on the categories or
                    specific pieces of personal information we collect and/or
                    sell (including how we use and disclose this information, to
                    whom we may sell it);
                  </li>
                  <li>That we delete any of your personal information;</li>
                  <li>
                    To not be discriminated against for exercising any of the
                    above rights.
                  </li>
                </ul>
              </div>

              <p className="text-secondary">
                If you would like to submit a request to exercise your
                California privacy rights under the CCPA, you may do so by
                contacting us. We will verify your request using information
                associated with your account, including your email address.
                Further identification may be required. You may also designate
                an authorized agent to act on your behalf.
              </p>

              <h3>Non-Discrimination</h3>
              <p className="text-secondary">
                We will not discriminate against you for exercising any of your
                CCPA rights. Unless permitted by the CCPA, we will not:
              </p>

              <div style={{ paddingLeft: 18, paddingBottom: 12 }}>
                <ul style={{ listStyleType: "disc" }}>
                  <li>Deny you goods or services;</li>
                  <li>
                    Charge you different prices or rates for goods or services,
                    including through granting discounts or other benefits, or
                    imposing penalties;
                  </li>
                  <li>
                    Provide you a different level or quality of goods or
                    services; or
                  </li>
                  <li>
                    Suggest that you may receive a different price or rate for
                    goods or services or a different level or quality of goods
                    or services.
                  </li>
                </ul>
              </div>
              <h3>Data Security</h3>
              <p className="text-secondary">
                We have implemented measures designed to secure your Personal
                Information from accidental loss and from unauthorized access,
                use, alteration, and disclosure.
              </p>
              <p className="text-secondary">
                Please bear in mind that submission of information over the
                Internet is never entirely secure. We cannot guarantee the
                security of information you submit via our Site. Any such
                submission is at your own risk.
              </p>

              <h3>Changes to Our Privacy Policy</h3>
              <p className="text-secondary">
                It is our policy to post any changes we make to this Policy on
                this page. If we make material changes to how we treat our
                users' Personal Information, we will notify you by the email
                address specified in your account and/or through a notice on the
                Website. You are responsible for ensuring we have an up-to-date
                active and deliverable email address for you, and for
                periodically visiting this Policy to check for any changes.
              </p>

              <h3>Contact Us</h3>
              <p className="text-secondary">
                To ask questions or comment about this Policy and our privacy
                practices, contact us at:
              </p>

              <p className="text-secondary">info@1CISO.io</p>
            </div>
          </div>
        </div>
        <div>
          <Footer />
          <ChatHistory
            onNavigate={() => {
              navigate("/");
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
