import React from "react";
import ChatBody from "../common/chat/chatBody";

const Chat = () => {
  return (
    <>
      <ChatBody />
    </>
  );
};

export default Chat;
