import React, { useState } from "react";
import LogoIcon from "../../assets/svg/logoIcon";
import { validate } from "../../utils/formValidate";
import { register } from "../../utils/endpoints";
import Alert from "../common/alerts/alert";
import Footer from "../common/footer";
import createAxiosInstance from "../../utils/axiosConfig";

const Register = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    company: "",
    title: "",
    industry: "Agriculture, Forestry, Fishing and Hunting",
    email: "",
    companySize: "0 - 500",
    budgetAccountability: "0 - $1M",
  });

  const [errors, setErrors] = useState<any>({});
  const [responseError, setResponseError] = useState<any>("");
  const [alertVisible, setAlertVisible] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validate({ [name]: value }, setErrors);
    // Confirm password validation
    if (name === "confirmPassword") {
      if (value !== formData.password) {
        // setConfirmPasswordError("Passwords do not match.");
        setErrors({ ...errors, confirmPassword: "Passwords do not match." });
      } else {
        setErrors({ ...errors, confirmPassword: "" });
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validate(formData, setErrors)) return;

    const payload = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      password: formData.password,
      company: formData.company,
      title: formData.title,
      industry: formData.industry,
      email: formData.email,
      company_size: formData.companySize,
      budget_accountability: formData.budgetAccountability,
    };
    try {
      const axiosInstance = createAxiosInstance();

      const response = await axiosInstance.post(register, payload);

      if (response.data?.user?.error) {
        setResponseError(response?.data?.user?.error);
        setAlertVisible(true);
      } else {
        setAlertVisible(true);
        setFormData({
          firstName: "",
          lastName: "",
          password: "",
          confirmPassword: "",
          company: "",
          title: "",
          industry: "Agriculture, Forestry, Fishing and Hunting",
          email: "",
          companySize: "0 - 500",
          budgetAccountability: "0 - $1M",
        });
      }
    } catch (err) {
      setResponseError("Some errors occured. Please try again later.");
      setAlertVisible(true);
    }
  };

  return (
    <section className="authentication h-100">
      <div className="row align-items-center justify-content-center h-100 w-100">
        <div className="col-md-10 col-lg-9 col-xl-7">
          <div className="card  mx-auto text-center">
            <div className="card-body">
              <div className="mb-3">
                <LogoIcon />
              </div>
              <h2 className="my-3 my-md-4">Sign Up </h2>
              {responseError && <p>{responseError}</p>}
              <form action="" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className={`form-control ${
                          errors.firstName ? "is-invalid" : ""
                        }`}
                        id="firstname"
                        name="firstName"
                        placeholder=""
                        value={formData.firstName}
                        onChange={handleChange}
                      />
                      <label htmlFor="firstname">First Name</label>
                      {errors.firstName && (
                        <div className="text-danger small">
                          {errors.firstName}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className={`form-control ${
                          errors.lastName ? "is-invalid" : ""
                        }`}
                        id="lastname"
                        name="lastName"
                        placeholder=""
                        value={formData.lastName}
                        onChange={handleChange}
                      />
                      <label htmlFor="lastname">Last Name</label>
                      {errors.lastName && (
                        <div className="text-danger small">
                          {errors.lastName}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-floating mb-3">
                  <input
                    type="password"
                    className={`form-control ${
                      errors.password ? "is-invalid" : ""
                    }`}
                    id="password"
                    name="password"
                    placeholder="********"
                    value={formData.password}
                    onChange={handleChange}
                  />
                  <label htmlFor="password">Password</label>
                  <div className="form-text text-start">
                    <i className="bi bi-info-circle"></i> Use 8 or more
                    characters with a mix of letters, numbers & symbols.
                  </div>
                  {errors.password && (
                    <div className="text-danger small">{errors.password}</div>
                  )}
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="password"
                    className={`form-control ${
                      errors.confirmPassword ? "is-invalid" : ""
                    }`}
                    id="confirm-password"
                    name="confirmPassword"
                    placeholder="********"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                  />
                  <label htmlFor="confirm-password">Confirm Password</label>
                  {errors.confirmPassword && (
                    <div className="text-danger small">
                      {errors.confirmPassword}
                    </div>
                  )}
                </div>
                <div className="row mb-3 ">
                  <div className="col">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className={`form-control ${
                          errors.company ? "is-invalid" : ""
                        }`}
                        id="company"
                        name="company"
                        placeholder=""
                        value={formData.company}
                        onChange={handleChange}
                      />
                      <label htmlFor="company">Company</label>
                      {errors.company && (
                        <div className="text-danger small">
                          {errors.company}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className={`form-control ${
                          errors.title ? "is-invalid" : ""
                        }`}
                        id="title"
                        name="title"
                        placeholder=""
                        value={formData.title}
                        onChange={handleChange}
                      />
                      <label htmlFor="title">Title</label>
                      {errors.title && (
                        <div className="text-danger small">{errors.title}</div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-floating mb-3 ">
                  <select
                    className={`form-select ${
                      errors.industry ? "is-invalid" : ""
                    }`}
                    aria-label="Floating label select example"
                    id="floatingSelect"
                    name="industry"
                    value={formData.industry}
                    onChange={handleChange}
                  >
                    <option selected>
                      Agriculture, Forestry, Fishing and Hunting
                    </option>
                    <option>Arts, Entertainment, and Recreation</option>
                    <option>Broadcasting</option>
                    <option>College, University, and Adult Education</option>
                    <option>Computer and Electronics Manufacturing</option>
                    <option>Construction</option>
                    <option>Finance and Insurance</option>
                    <option>Government and Public Administration</option>
                    <option>Health Care and Social Assistance</option>
                    <option>Homemaker</option>
                    <option>Hotel and Food Services</option>
                    <option>Information Services and Data Processing</option>
                    <option>Legal Services</option>
                    <option>Military</option>
                    <option>Mining</option>
                    <option>Other Education Industry</option>
                    <option>Other Information Industry</option>
                    <option>Other Manufacturing</option>
                    <option>Primary/Secondary (K-12) Education</option>
                    <option>Publishing</option>
                    <option>Real Estate, Rental and Leasing</option>
                    <option>Religious</option>
                    <option>Retail</option>
                    <option>Scientific or Technical Services</option>
                    <option>Software</option>
                    <option>Telecommunications</option>
                    <option>Transportation and Warehousing</option>
                    <option>Utilities</option>
                    <option>Wholesale</option>
                    <option>Other (fill in)</option>
                  </select>
                  <label htmlFor="floatingSelect">Industry</label>
                  {errors.industry && (
                    <div className="text-danger small">{errors.industry}</div>
                  )}
                </div>

                <div className="form-floating mb-3 ">
                  <input
                    type="email"
                    className={`form-control ${
                      errors.email ? "is-invalid" : ""
                    }`}
                    id="email"
                    name="email"
                    placeholder="name@example.com"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <label htmlFor="email">Email</label>
                  <div className="form-text text-start">
                    <i className="bi bi-info-circle"></i> Add your Work email
                    address
                  </div>
                  {errors.email && (
                    <div className="text-danger small">{errors.email}</div>
                  )}
                </div>

                <div className="row mb-3 ">
                  <div className="col">
                    <div className="form-floating">
                      <select
                        className={`form-select ${
                          errors.companySize ? "is-invalid" : ""
                        }`}
                        id="floatingSelect"
                        aria-label="Floating label select example"
                        name="companySize"
                        value={formData.companySize}
                        onChange={handleChange}
                      >
                        <option selected value="0 - 500">
                          0 - 500
                        </option>
                        <option value="500 - 5,000">500 - 5,000</option>
                        <option value="5,000 – 10,000">5,000 – 10,000</option>
                        <option value="10,000 – 30,000">10,000 – 30,000</option>
                        <option value="30,000+">30,000+ </option>
                      </select>
                      <label htmlFor="floatingSelect">Company Size</label>
                      {errors.companySize && (
                        <div className="text-danger small">
                          {errors.companySize}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-floating">
                      <select
                        className={`form-select ${
                          errors.budgetAccountability ? "is-invalid" : ""
                        }`}
                        id="floatingSelect"
                        aria-label="Floating label select example"
                        name="budgetAccountability"
                        value={formData.budgetAccountability}
                        onChange={handleChange}
                      >
                        <option selected value="0 - $1M">
                          0 - $1M{" "}
                        </option>
                        <option value="$1M – $30M">$1M – $30M</option>
                        <option value="$30M - $60M">$30M - $60M</option>
                        <option value="$60M - $90M">$60M - $90M</option>
                        <option value="$90M - $150M">$90M - $150M</option>
                        <option value="$60M - $90M">$60M - $90M</option>
                        <option value="$150M +">$150M +</option>
                      </select>
                      <label htmlFor="floatingSelect">
                        Budget Accountability
                      </label>
                      {errors.budgetAccountability && (
                        <div className="text-danger small">
                          {errors.budgetAccountability}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <button
                    type="submit"
                    className="btn btn-primary w-100"
                    disabled={Object.keys(errors).length > 0}
                  >
                    Sign Up
                  </button>
                </div>
              </form>
              {responseError && <p>{responseError}</p>}
              <h3
                className="text-secondary my-3"
                style={{ fontWeight: "450", fontSize: "1.3rem" }}
              >
                Already have an Account?{" "}
                <a href="/" className="btn btn-link">
                  Sign In
                </a>{" "}
              </h3>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <Alert
        message={`${
          responseError.length > 1
            ? responseError
            : "User registration successful."
        }`}
        type={`${responseError.length > 1 ? "danger" : "success"}`}
        show={alertVisible}
        onClose={() => setAlertVisible(false)}
      />
    </section>
  );
};

export default Register;
