import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";

// interface AuthContextType {
//   isAuthenticated: boolean;
//   userId: string | null;
//   login: () => void;
//   logout: () => void;
// }

interface AuthContextType {
  isAuthenticated: boolean;
  userId: number | null;
  token: string | null;
  login: (userId: number, token: string, userData: object) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [userId, setUserId] = useState<number | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [userData, setUserData] = useState<object | null>(null);
  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");
    const storedToken = localStorage.getItem("token");
    if (storedUserId && storedToken) {
      setIsAuthenticated(true);
      setUserId(Number(storedUserId));
      setToken(storedToken);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  const login = (id: number, jwtToken: string, userData: object) => {
    setIsAuthenticated(true);
    setUserId(Number(id));
    setToken(jwtToken);
    setUserData(userData);
    localStorage.setItem("userId", `${id}`);
    localStorage.setItem("token", jwtToken);
    localStorage.setItem("userData", JSON.stringify(userData));
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUserId(null);
    setToken(null);
    localStorage.removeItem("userId");
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, userId, token, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
