import React, { useState } from "react";
import { validate } from "../../../../utils/formValidate";
import { useAuth } from "../../../../context/authContext";
import { useNavigate } from "react-router-dom";
import { add_feedback } from "../../../../utils/endpoints";
import Alert from "../../alerts/alert";
import createAxiosInstance from "../../../../utils/axiosConfig";

const ContactForm = ({ type }: { type: string }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userData") || "{}");
  const [formData, setFormData] = useState({
    first_name: userData.first_name || "",
    last_name: userData.last_name || "",
    contact_phone: "",
    email: userData.email || "",
    comments: userData.comments || "",
  });
  const [errors, setErrors] = useState<any>({});
  const [responseError, setResponseError] = useState<any>("");
  const [alertVisible, setAlertVisible] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validate({ [name]: value }, setErrors);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validate(formData, setErrors)) return;

    const payload = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      contact_phone: formData.contact_phone,
      comments: formData.comments,
    };

    try {
      const axiosInstance = createAxiosInstance();

      const response = await axiosInstance.post(add_feedback, payload);
      if (response.status == 401) {
        logout();
        navigate("/");
        return;
      }

      if (response.data?.user?.error) {
        setResponseError(response?.data?.user?.error);
        setAlertVisible(true);
      } else {
        // setResponseError("Feedback submitted successfully...!");
        setAlertVisible(true);
        setFormData({
          first_name: "",
          last_name: "",
          contact_phone: "",
          email: "",
          comments: "",
        });
      }
    } catch (err) {
      setResponseError("Some errors occured. Please try again later.");
      setAlertVisible(true);
      console.log(err);
    }
  };

  return (
    <form action="" onSubmit={handleSubmit}>
      <div className="row mb-3">
        <div className="col">
          <div className="form-floating mb-3">
            <input
              type="text"
              className={`form-control ${
                errors?.first_name ? "is-invalid" : ""
              }`}
              id="firstname"
              placeholder=""
              name="first_name"
              onChange={handleChange}
              value={formData.first_name}
            />
            <label htmlFor="firstname">First Name</label>
            {errors?.first_name && (
              <div className="text-danger small">{errors?.first_name}</div>
            )}
          </div>
        </div>
        <div className="col">
          <div className="form-floating mb-3">
            <input
              type="text"
              className={`form-control ${
                errors?.last_name ? "is-invalid" : ""
              }`}
              id="lastname"
              placeholder=""
              name="last_name"
              onChange={handleChange}
              value={formData.last_name}
            />
            <label htmlFor="lastname">Last Name</label>
            {errors?.last_name && (
              <div className="text-danger small">{errors?.last_name}</div>
            )}
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <div className="form-floating mb-3">
            <input
              type="tel"
              className={`form-control ${
                errors.contact_phone ? "is-invalid" : ""
              }`}
              id="contactphone"
              placeholder=""
              name="contact_phone"
              onChange={handleChange}
              value={formData.contact_phone}
            />
            <label htmlFor="contactphone">Contact Phone</label>
            {errors.contact_phone && (
              <div className="text-danger small">{errors.contact_phone}</div>
            )}
          </div>
        </div>
        <div className="col">
          <div className="form-floating mb-3">
            <input
              type="email"
              className={`form-control ${errors.email ? "is-invalid" : ""}`}
              id="email"
              placeholder=""
              name="email"
              onChange={handleChange}
              value={formData.email}
            />
            <label htmlFor="email"> Email</label>
            {errors.email && (
              <div className="text-danger small">{errors.email}</div>
            )}
          </div>
        </div>
      </div>
      <div className="form-floating mb-3">
        <textarea
          className={`form-control ${errors.comments ? "is-invalid" : ""}`}
          style={{ height: "200px" }}
          placeholder="Leave a comment here"
          id="floatingTextarea"
          name="comments"
          onChange={handleChange}
          value={formData.comments}
        ></textarea>
        <label htmlFor="floatingTextarea">Comments</label>
        {errors.comments && (
          <div className="text-danger small">{errors.comments}</div>
        )}
      </div>
      <div className="mb-3 text-end">
        <button type="submit" className="btn btn-secondary">
          {type == "feedback" ? "Submit Feedback" : "Submit Form"}
        </button>
      </div>
      <Alert
        message={`${
          responseError.length > 1
            ? responseError
            : "Feedback form was submitted successfully."
        }`}
        type={`${responseError.length > 1 ? "danger" : "success"}`}
        show={alertVisible}
        onClose={() => setAlertVisible(false)}
      />
    </form>
  );
};

export default ContactForm;
