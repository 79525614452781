import React, { useEffect, useState } from "react";
import { parseISO, isToday, isYesterday, isThisWeek } from "date-fns";
import { useCases } from "../../../utils/constants";
import "../../../assets/scss/scrollbar.css";
import { useChatHistory } from "../../../context/chatHistoryContext";
import { useChat } from "../../../context/chatContext";

interface Conversation {
  id: string;
  user_id: string;
  conversation: Array<{
    question: string;
    response: string;
    label: string;
    date: string;
  }>;
}
interface ChatHistoryProps {
  onNavigate: (path: string) => void;
}

// interface ChatHistoryProps {
//   resumeChat: (conversation: Conversation) => void;
//   conversation: Conversation[];
//   loading: boolean;
// }

const categorizeConversations = (conversations: Conversation[]) => {
  const today: Conversation[] = [];
  const yesterday: Conversation[] = [];
  const lastWeek: Conversation[] = [];

  conversations.forEach((conversation) => {
    if (conversation.conversation.length > 1) {
      const date = parseISO(conversation.conversation[0].date);
      if (isToday(date)) {
        today.push(conversation);
      } else if (isYesterday(date)) {
        yesterday.push(conversation);
      } else if (isThisWeek(date)) {
        lastWeek.push(conversation);
      }
    }
  });

  return { today, yesterday, lastWeek };
};

const ChatHistory: React.FC<ChatHistoryProps> = ({ onNavigate }) => {
  const { resumeChat } = useChat();
  const { isOpen, conversations, loading, closeChatHistory } = useChatHistory();
  const [visibleToday, setVisibleToday] = useState(3);
  const [visibleYesterday, setVisibleYesterday] = useState(3);
  const [visibleLastWeek, setVisibleLastWeek] = useState(3);

  const { today, yesterday, lastWeek } = categorizeConversations(conversations);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);

  const handleResumeChat = (conversation: any) => {
    resumeChat(conversation, () => {
      onNavigate("/");
      closeChatHistory();
    });
    // closeChatHistory();
  };

  const chatTitle = (conv: Conversation, useCaseObj: any) => {
    let label = conv?.conversation[1]?.label.split("_");
    let useCase = Number(label[label.length - 1]);

    if (label[label.length - 1].split(".").length >= 2) {
      useCase = Number(label[label.length - 1].split(".")[0]);
    }
    if (useCase == useCaseObj?.value) {
      return `${useCaseObj?.name} / ${conv.conversation[1].question.slice(
        0,
        20
      )}`;
    }
  };

  const renderConversations = (
    category: Conversation[],
    visibleCount: number,
    setVisibleCount: (count: number) => void
  ) => (
    <>
      {category.slice(0, visibleCount).map((conv, index) => (
        <li
          key={index}
          className="list-group-item text-truncate"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          style={{ cursor: "pointer" }}
        >
          <a href="#" onClick={() => handleResumeChat(conv)}>
            {useCases.map((value) => chatTitle(conv, value))}
          </a>
        </li>
      ))}
      {visibleCount < category.length && (
        <li
          className="list-group-item text-center mt-2"
          style={{ cursor: "pointer", color: "#205493", fontSize: 14 }}
          onClick={() => setVisibleCount(visibleCount + 3)}
        >
          Load More
        </li>
      )}
    </>
  );

  if (!isOpen) return null;
  return (
    <>
      <div
        className="offcanvas-backdrop fade show"
        onClick={closeChatHistory}
      ></div>
      <div
        className="offcanvas offcanvas-start show"
        tabIndex={-1}
        // id="offcanvasExample"
        // aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            All Conversations
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={closeChatHistory}
          ></button>
        </div>
        <div className="offcanvas-body scroller">
          {loading ? (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <>
              {today.length > 0 && (
                <>
                  <h6 className="text-blue mb-3 ps-3">
                    <i className="bi bi-calendar"></i> Today
                    <span
                      style={{
                        marginLeft: "18%",
                        fontSize: "0.9rem",
                        fontWeight: 500,
                      }}
                      className="badge bg-light text-blue"
                    >
                      {today.length}
                    </span>
                  </h6>
                  <ul className="list-group list-group-flush mb-5">
                    {renderConversations(today, visibleToday, setVisibleToday)}
                  </ul>
                </>
              )}
              {yesterday.length > 0 && (
                <>
                  <h6 className="text-blue mb-3 ps-3">
                    <i className="bi bi-calendar"></i> Yesterday
                    <span
                      style={{
                        marginLeft: "10%",
                        fontSize: "0.9rem",
                        fontWeight: 500,
                      }}
                      className="badge bg-light text-blue"
                    >
                      {yesterday.length}
                    </span>
                  </h6>
                  <ul className="list-group list-group-flush mb-5">
                    {renderConversations(
                      yesterday,
                      visibleYesterday,
                      setVisibleYesterday
                    )}
                  </ul>
                </>
              )}
              {lastWeek.length > 0 && (
                <>
                  <h6 className="text-blue mb-3 ps-3">
                    <i className="bi bi-calendar"></i> Last Weeks
                    <span
                      style={{
                        marginLeft: "10%",
                        fontSize: "0.9rem",
                        fontWeight: 500,
                      }}
                      className="badge bg-light text-blue"
                    >
                      {lastWeek.length}
                    </span>
                  </h6>
                  <ul className="list-group list-group-flush mb-5">
                    {renderConversations(
                      lastWeek,
                      visibleLastWeek,
                      setVisibleLastWeek
                    )}
                  </ul>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ChatHistory;
