import React, { useState } from "react";
import { useChatHistory } from "../../../context/chatHistoryContext";
import { useAuth } from "../../../context/authContext";
import Alert from "../alerts/alert";

const Footer: React.FC = () => {
  const { openChatHistory } = useChatHistory();
  const { isAuthenticated } = useAuth();
  const [alertVisible, setAlertVisible] = useState(false);

  const handleAllConversationClick = (
    e: React.MouseEvent<HTMLAnchorElement>
  ) => {
    e.preventDefault();
    if (isAuthenticated) {
      openChatHistory();
    } else {
      setAlertVisible(true);
    }
  };

  return (
    <section className="chatfooterCommon mt-auto">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="row">
              <div className="col-4">
                <span>
                  <a
                    className={`small ${!isAuthenticated ? "disabled" : ""}`}
                    role="button"
                    onClick={handleAllConversationClick}
                  >
                    <i className="bi bi-chat-right-dots"></i> All Conversation
                  </a>
                </span>
              </div>
              <div className="col-4">
                <span style={{}}>
                  <a className="small" href="/terms-and-conditions">
                    Terms and Conditions
                  </a>
                </span>
              </div>
              <div className="col-4" style={{ textAlign: "center" }}>
                <span style={{}}>
                  <a className="small" href="/privacy-policy">
                    Privacy Policy
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Alert
        message="Loads only when logged in."
        type="warning"
        show={alertVisible}
        onClose={() => setAlertVisible(false)}
      />
    </section>
  );
};

export default Footer;
