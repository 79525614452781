import path from "path";
import Pricing from "../components/common/pricing";
import Chat from "../components/private/chat";
import Feedback from "../components/private/feedback";
import ChangePassword from "../components/private/changePassword";
import UploadFiles from "../components/private/uploadFiles";
import IngestData from "../components/private/ingestData";
import ContactSales from "../components/private/contactSales";

const privateRoutes = [
  { path: "/", element: <Chat /> },
  { path: "/chat", element: <Chat /> },
  { path: "/feedback", element: <Feedback /> },
  { path: "/pricing", element: <Pricing /> },
  { path: "/changeAccountPassword", element: <ChangePassword /> },
  { path: "/uploadFiles", element: <UploadFiles /> },
  { path: "/ingest", element: <IngestData /> },
  { path: "/contactSales", element: <ContactSales /> },
  //   { path: "/profile", component: Profile },
];

export default privateRoutes;
